// import UseMetaMask from "@/config/helpers/web3Logic";
import logoLight from "@/assets/images/logo/seed-logo-dark-m.svg";
import logoDark from "@/assets/images/logo/seed-logo-light-m.svg";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";
// import LightDark from "../common/LightDark";
import { getCookie } from "@/config/helpers/api_helper";
import { createSelectorMyProfileData } from "@/config/helpers/createReduxSelectors";
import {
  ResetTopArtists,
  exploreSearchReset,
  getMyProfile,
  resetOnlyNftData,
} from "@/store/actions";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import { EnumVerifications } from "../constants/layout";
// import GlobalSearch from "../common/globalSearch";
// import HomeMenuModal from "../common/modals/homeMenuModal";

const HomeMenuModal = dynamic(() => import("../common/modals/homeMenuModal"), {
  ssr: false,
});
// const GlobalSearch = dynamic(() => import("../common/globalSearch"), {
//   ssr: false,
// });

const GlobalSearch2 = dynamic(() => import("../common/globalSearch2"), {
  ssr: false,
});

const UseMetaMask = dynamic(() => import("@/config/helpers/web3Logic"), {
  ssr: false,
});
const LightDark = dynamic(() => import("../common/LightDark"));

const selectMyProfileState = (state) => state.MyProfile;

function Header({
  onChangeLayoutMode,
  layoutModeType,
  showMeta = true,
  showWC = true,
  showSui = true,
  showW3A = true,
}) {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [storageItem, setStorageItem] = useState(null);
  const [isAuthUser, setIsAuthUser] = useState(false);

  const { error, success, MyProfileUser, message, loading } = useSelector(
    createSelectorMyProfileData
  );

  useEffect(() => {
    // Check if running on client side
    if (typeof window !== "undefined" && getCookie("authentication") !== "") {
      const item = JSON.parse(getCookie("authentication"));
      setStorageItem(item);
      setIsAuthUser(!!getCookie("authentication"));
    }
  }, [isAuthUser]);

  useEffect(() => {
    if (storageItem && isEmpty(MyProfileUser)) {
      setTimeout(() => {
        dispatch(getMyProfile(storageItem.UserName));
      }, 300);
    }
  }, [storageItem, MyProfileUser, userData, dispatch]);

  useEffect(() => {
    setUserData(MyProfileUser?.User);
  }, [MyProfileUser, userData]);

  const memoizedLightDark = useMemo(
    () => (
      <LightDark
        layoutMode={layoutModeType}
        onChangeLayoutMode={onChangeLayoutMode}
      />
    ),
    [layoutModeType, onChangeLayoutMode]
  );

  const [loadingWindow, setLoadingWindow] = useState(true);
  useEffect(() => {
    const handleLoad = () => setLoadingWindow(false);
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <>
      <header className="header js-header" data-id="#header">
        <div className="header__center center">
          <Link className="header__logo" href="/">
            <Image
              width={128}
              height={37}
              loading="lazy"
              className="some-icon"
              src={logoLight}
              alt="Seed logo dark"
            />
            <Image
              width={128}
              height={37}
              loading="lazy"
              className="some-icon-dark"
              src={logoDark}
              alt="Seed logo light"
            />
          </Link>

          <div className="header__wrapper js-header-wrapper">
            <nav className="header__nav">
              {/* <div className="tooltip1"> */}
              {memoizedLightDark}

              <GlobalSearch2 buttonClass={"header__popup mobile-hide"} />

              <Link
                className="header__link"
                href="/nfts"
                onClick={() => {
                  dispatch(exploreSearchReset());
                  window.location.href = "/nfts";
                }}
              >
                Explore
              </Link>
              <Link className="header__link" href="/inspire">
                Inspire
              </Link>

              <Link
                className="header__link"
                href="https://blog.seed.photo"
                target="_blank"
              >
                Blog
              </Link>

              <Link
                className="header__link"
                href="/top-artists"
                onClick={() => {
                  dispatch(ResetTopArtists());
                }}
              >
                Artists
              </Link>
              <Link className="header__link" href="/tutorials">
                Guide
              </Link>
            </nav>
          </div>

          <div>
            {typeof window !== "undefined" &&
            getCookie("authentication") !== "" &&
            !!getCookie("authentication") &&
            userData &&
            !isEmpty(MyProfileUser) ? (
              userData.PhotographerVerification === null ||
              userData.PhotographerVerification ===
                EnumVerifications.Rejected ||
              userData.PhotographerVerification ===
                EnumVerifications.Pending ? (
                <>
                  {/* <Link
                    className="button header__button header__upload"
                    href="/account/verification-form"
                    onClick={() => {
                      dispatch(resetMyProfile());
                    }}
                  >
                    Join Now
                  </Link> */}
                </>
              ) : userData.PhotographerVerification ===
                EnumVerifications.Accepted ? (
                <>
                  <Link
                    className="button header__button header__upload"
                    href="/nfts/upload"
                    onClick={() => dispatch(resetOnlyNftData())}
                  >
                    Upload
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    className="button header__button header__upload"
                    href="/account/verification-form"
                  >
                    Join Now
                  </Link>
                </>
              )
            ) : null}
          </div>

          <UseMetaMask
            userData={userData}
            isHeader={true}
            customeClass="button header__button header__connect"
            showMeta={showMeta}
            showWC={showWC}
            showSui={showSui}
            showW3A={showW3A}
          />

          <HomeMenuModal myUser={userData} />
        </div>
      </header>
      {loadingWindow && (
        <section className="headerprogress__area">
          <div className="headerprogress__bar">
            <div
              className="headerprogress__progress"
              id="js-headerprogress"
            ></div>
          </div>
        </section>
      )}
    </>
  );
}

export default React.memo(Header);
